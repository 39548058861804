import htmx from "htmx.org";
import Alpine from "alpinejs";
import { createCharacterQuizHandler } from "./character_quiz";

Alpine.data("createCharacterQuizHandler", createCharacterQuizHandler);
Alpine.start();

htmx.on("htmx:responseError", function (evt) {
    window.location.href = `/layout/boost-error/?m=${encodeURIComponent(evt.detail.error)}`;
});
