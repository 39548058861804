class CharacterQuizHandler {
    constructor(questionId) {
        this.disabled = false;
        this.correct = "";
        this.incorrect = "";
        this.answered = false;
        this.questionId = questionId;
    }

    getColorClass(answer) {
        if (this.correct == answer) {
            return "bg-green-400 hover:bg-green-400";
        } else if (this.incorrect == answer) {
            return "bg-red-400 hover:bg-red-400";
        } else if (this.answered) {
            return "bg-neutral-300 hover:bg-neutral-300";
        } else {
            return "bg-neutral-300 hover:bg-neutral-400";
        }
    }

    isDisabled() {
        return this.disabled;
    }

    showNextButton() {
        return this.answered;
    }

    async submitAnswer(e) {
        const answer = e.target.dataset.answer;
        const response = await fetch(`/study/character-quiz/answer/${this.questionId}/${answer}`);
        const result = await response.json();
        this.disabled = true;
        this.answered = true;
        this.correct = result.answer;
        if (!result.correct) {
            this.incorrect = answer;
        }
    }
}

export const createCharacterQuizHandler = (questionId) => {
    return new CharacterQuizHandler(questionId);
};
